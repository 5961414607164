/**
 * Videos Database can be added here.
 * You can add videos of your wish with different attributes
 * */
export const videos = [
  {
    _id: "gO8N3L_aERg",
    title: "Fire Drill",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/gO8N3L_aERg/hq720.jpg",
  },
  {
    _id: "Xnk4seEHmgw",
    title: "Jim's Pranks Against Dwight",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/Xnk4seEHmgw/hq720.jpg",
  },
  {
    _id: "g8kCYiKkS4Q",
    title: "Michael Knows a Secret ",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/g8kCYiKkS4Q/hq720.jpg",
  },
  {
    _id: "NEV6ko7PIpE",
    title: "Office Cameos",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/NEV6ko7PIpE/hq720.jpg",
  },
  {
    _id: "6mIpzyVv8RU",
    title: "Gabe Loves Girl Talk ",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/6mIpzyVv8RU/hq720.jpg",
  },
  {
    _id: "4BUgLMldFQY",
    img: "https://i.ytimg.com/vi/4BUgLMldFQY/hq720.jpg",
    title: "The Office - Signs of a Declining Sitcom",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
  },
  {
    _id: "ryxUeWEcUqE",
    title: "The Office Wedding Dance",
    img: "https://i.ytimg.com/vi/ryxUeWEcUqE/hq720.jpg",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
  },
  {
    _id: "VSv64fV0LDk",
    title: "Best of Kelly Kapoor",
    img: "https://i.ytimg.com/vi/VSv64fV0LDk/hq720.jpg",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
  },
  {
    _id: "MAg2jw0uZ3I",
    title: "China, China, China",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "	https://i.ytimg.com/vi/MAg2jw0uZ3I/hq720.jpg",
  },
  {
    _id: "RFQRM1B2AKA",
    title: "The IT Guy Exposes Everyone's Secrets",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "	https://i.ytimg.com/vi/RFQRM1B2AKA/hq720.jpg",
  },
  {
    _id: "D-9E-5Zm6-0",
    title: "Michael Visits Pam's Art Show",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/D-9E-5Zm6-0/hq720.jpg",
  },
  {
    _id: "FdJpweIPbWw",
    title: "why are you the way that you are",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/FdJpweIPbWw/hq720.jpg",
  },
  {
    _id: "OkPp0I5PXJw",
    title: "911 CALL ME",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/OkPp0I5PXJw/hq720.jpg",
  },
  {
    _id: "96ix3P9WaRc",
    title: "i'll kill you",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/96ix3P9WaRc/hq720.jpg",
  },
  {
    _id: "OvoOnZkQCcw",
    title: "Andy Thinks He's Gay",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/OvoOnZkQCcw/hq720.jpg",
  },
  {
    _id: "c0AMd6uSQAw",
    title: "CLASH OF TITANS",
    creator: "The Office US",
    category: "the office",
    logo: "https://yt3.ggpht.com/ytc/AKedOLR6J8RinEENwSX3uR4ab38mKWrIuFqnWRZQaKxEvQ=s68-c-k-c0x00ffffff-no-rj",
    img: "https://i.ytimg.com/vi/c0AMd6uSQAw/hq720.jpg",
  },

  {
    _id: "9-hmg1UHySY",
    title: "Phoebe, Chandler, Monica Babysit The Triplets ",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/9-hmg1UHySY/hq720.jpg",
  },
  {
    _id: "L5STHzbIglg",
    title: "The Friends Meet Rachel's Spoiled Sister",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/L5STHzbIglg/hq720.jpg",
  },
  {
    _id: "Xs-HbHCcK58",
    title: "FRIENDS OG",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/Xs-HbHCcK58/hq720.jpg",
  },
  {
    _id: "E47mx4rsVCI",
    title: "Monica and Chandler Almost Get Married",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/E47mx4rsVCI/hq720.jpg",
  },
  {
    _id: "_I0N-w7QEHc",
    title: "Chandler and Monica Debate Their New Guest Room",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/_I0N-w7QEHc/hq720.jpg",
  },
  {
    _id: "iLy0E48RPyE",
    title: "Friends: Rachel Being Rachel (Mashup)",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/iLy0E48RPyE/hq720.jpg",
  },
  {
    _id: "221F55VPp2M",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/221F55VPp2M/hq720.jpg",
  },
  {
    _id: "P5TMcx0ofEc",
    title: "Bests scenes of FRIENDS",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/P5TMcx0ofEc/hq720.jpg",
  },
  {
    _id: "XZVHmRvfDHM",
    title: "Friends: Joey Finds Out",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/XZVHmRvfDHM/hq720.jpg",
  },
  {
    _id: "q8WgsfkofOM",
    title: "Chandler, Ross & Joey being ICONIC bestfriends",
    creator: "Friends",
    category: "friends",
    logo: "https://m.media-amazon.com/images/M/MV5BNDVkYjU0MzctMWRmZi00NTkxLTgwZWEtOWVhYjZlYjllYmU4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_.jpg",
    img: "https://i.ytimg.com/vi/q8WgsfkofOM/hq720.jpg",
  },
  {
    _id: "i637OBxyWQY",
    title: "Harvey & Alex Are Facing a Tough Deal",
    creator: "Suits",
    category: "suits",
    logo: "https://upload.wikimedia.org/wikipedia/en/7/78/Suits_season_7_dvd_cover.jpg",
    img: "https://i.ytimg.com/vi/i637OBxyWQY/hq720.jpg",
  },
  {
    _id: "a5boqgJQHWA",
    title: "Louis Identifies His Aggressor ",
    creator: "Suits",
    category: "suits",
    logo: "https://upload.wikimedia.org/wikipedia/en/7/78/Suits_season_7_dvd_cover.jpg",
    img: "https://i.ytimg.com/vi/a5boqgJQHWA/hq720.jpg",
  },
  {
    _id: "WOEUNjY0nLw",
    title: "Harvey and Louis Try Couple Counseling",
    creator: "Suits",
    category: "suits",
    logo: "https://upload.wikimedia.org/wikipedia/en/7/78/Suits_season_7_dvd_cover.jpg",
    img: "https://i.ytimg.com/vi/WOEUNjY0nLw/hq720.jpg",
  },
  {
    _id: "apGv7QOoU58",
    title: "''THIS IS OFFENSIVE!'' | Louis Gives Mike A Business Lesson",
    creator: "Suits",
    category: "suits",
    logo: "https://upload.wikimedia.org/wikipedia/en/7/78/Suits_season_7_dvd_cover.jpg",
    img: "https://i.ytimg.com/vi/apGv7QOoU58/hq720.jpg",
  },
  {
    _id: "gd8XHU-iTwE",
    title: "Jessica, Louis, Harvey Getting High Together",
    creator: "Suits",
    category: "suits",
    logo: "https://upload.wikimedia.org/wikipedia/en/7/78/Suits_season_7_dvd_cover.jpg",
    img: "https://i.ytimg.com/vi/gd8XHU-iTwE/hq720.jpg",
  },
  {
    _id: "LizWcUruPOA",
    title: "Harvey Gets In The Ring With Sam",
    creator: "Suits",
    category: "suits",
    logo: "https://upload.wikimedia.org/wikipedia/en/7/78/Suits_season_7_dvd_cover.jpg",
    img: "https://i.ytimg.com/vi/LizWcUruPOA/hq720.jpg",
  },
];
